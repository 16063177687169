import { useState } from "react";

export default function ChairGroup(props) {
  const [hovered, setHovered] = useState(false);

  return (
    <>
      <foreignObject
        x={props.x}
        y={props.y + 45 * (!props.horizontal ? props.quantity : 1)}
        width={150}
        height={450}>
        <div className="progress my-2" style={{ height: "20px" }}>
          <div
            className="progress-bar"
            style={{
              width: (props.occupied / props.quantity) * 100 + "%",
              backgroundColor:
                props.occupied / props.quantity < 0.7
                  ? "#53b8aa"
                  : "darkorange",
            }}></div>
        </div>
        <span style={{ fontSize: "1.1rem" }}>
          {props.occupied}/{props.quantity} chairs occ.
        </span>
      </foreignObject>
      <g>
        <rect
          style={{ cursor: "pointer", outline: hovered ? "1px solid" : "" }}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          fill="gray"
          width={45 * (!props.horizontal ? 1 : props.quantity)}
          height={45 * (!props.horizontal ? props.quantity : 1)}
          rx={10}
          x={props.x}
          y={props.y}
        />
      </g>
    </>
  );
}
