import { useContext } from "react";
import { ExpandedContext, HoveredContext } from "../../../pages/Location";
import HistoryItem from "./HistoryItem";
import { v4 as uuidv4 } from "uuid";
import Button from "../../atoms/Button";
import Icon from "../../atoms/Icon";

export default function Popup() {
  const [hoveredItem, setHoveredItem] = useContext(HoveredContext);
  const [expanded, setExpanded] = useContext(ExpandedContext);

  return (
    <>
      {hoveredItem.length !== 0 && (
        <foreignObject
          transform="scale(1.3)"
          width={300}
          height={
            !expanded ? (hoveredItem.periods?.length !== 0 ? 260 : 200) : 400
          }
          x={
            hoveredItem.x + (hoveredItem.horizontal ? 10 : 95) < 1200
              ? hoveredItem.x + (hoveredItem.horizontal ? 10 : 95)
              : 1150
          }
          y={
            hoveredItem.y + (hoveredItem.horizontal ? 0 : -70) < 400
              ? hoveredItem.y + (hoveredItem.horizontal ? 0 : -70)
              : 400
          }
          onMouseLeave={() => setHoveredItem([])}
          className="bg-light p-4 rounded shadow">
          <div className="text-center">
            <div className="h3">
              <Icon
                name={
                  "circle" +
                  (hoveredItem.occupied ? "-fill text-danger" : " text-success")
                }
                className="me-2"
              />
              {hoveredItem.longLabel}
            </div>
            <div>Today's Average:</div>
            <div className="h4 fst-italic">
              {(isNaN(
                hoveredItem.periods.reduce((x, y) => x + y.length - 1, 0) /
                  hoveredItem.periods.length
              )
                ? 0
                : hoveredItem.periods.reduce((x, y) => x + y.length - 1, 0) /
                  hoveredItem.periods.length
              ).toFixed(0)}{" "}
              mins.
            </div>

            <div>Today's Max:</div>
            <div className="h4 fst-italic">
              {!isFinite(
                Math.max(
                  ...(hoveredItem.periods || []).map((x) => x.length - 1)
                )
              )
                ? 0
                : Math.max(
                    ...(hoveredItem.periods || []).map((x) => x.length - 1)
                  )}{" "}
              mins.
            </div>
            {hoveredItem.periods?.length !== 0 && (
              <Button
                onClick={() => setExpanded(!expanded)}
                className="m-2"
                icon="clock-history"
                text={(expanded ? "Hide" : "Show") + " 3 Most Recent"}
              />
            )}
            {expanded && (
              <div className="py-3">
                <div className="row small">
                  <span className="col">Start</span>
                  <span className="col">End</span>
                  <span className="col fw-bold"># Minutes</span>
                </div>
                <hr className="my-1" />
                {hoveredItem.periods.slice(-3).map((x) => (
                  <HistoryItem key={uuidv4()} item={x} />
                ))}
              </div>
            )}
          </div>
        </foreignObject>
      )}
    </>
  );
}
