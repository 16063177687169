import BoothCard from "../components/organisms/Analytics/BoothCard";
import { useContext, useEffect, useRef, useState } from "react";
import {
  Bar,
  CartesianGrid,
  Cell,
  Label,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { v4 as uuidv4 } from "uuid";
import AuthContext from "../contexts/auth-context";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import Button from "../components/atoms/Button";
import InputGroup from "../components/molecules/InputGroup";
import { formatTimestamp, generateDay, getStreaks } from "../util";
import Spinner from "../components/atoms/Spinner";
import Chart from "../components/atoms/Chart";
import TotalBoothCard from "../components/organisms/Analytics/TotalBoothCard";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import PDFPage from "../components/organisms/Reports/PDFPage";
import Brand from "../components/organisms/Reports/Brand";

moment.tz.setDefault("America/New_York");
const ids = [1058, 1726, 295];
let timer;

export default function Reports() {
  const navigate = useNavigate();
  const pdfRef = useRef();
  const [selectedLocations, setSelectedLocations] = useState([]);

  const authCtx = useContext(AuthContext);

  const [startDate, setStartDate] = useState(
    moment(new Date()).startOf("day").subtract(1, "days").toDate()
  );
  const [data, setData] = useState([]);
  const [booths, setBooths] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(15);

  const [saving, setSaving] = useState(false);

  const onChangeStartDate = (e) => setStartDate(e.target.value);

  const getData = async () => {
    setLoading(true);
    let allData = [];
    let allBooths = [];
    for (let i = 1; i <= 3; i++) {
      const response = await fetch(
        "https://api.queuesapp.com/get-waffle-house-data",
        {
          headers: {
            "Content-Type": "application/json",
            token: authCtx.token,
          },
          method: "POST",
          body: JSON.stringify({
            locationId: i,
            date: moment(startDate).format("yyyy-MM-DD"),
          }),
        }
      );
      const data = await response.json();

      let k = processData(data, i);

      let l = getBooths(k, i);

      allData.push({
        id: ids[i - 1],
        data: k,
      });
      allBooths.push({ locationId: ids[i - 1], booths: l });
    }
    setStatus(100);
    setTimeout(() => {
      setData({ occData: allData });
      setBooths(allBooths);
      setLoading(false);
    }, 1000);
  };

  const processData = (data_, id) => {
    let currentLocation = require("../floors/locations.json").locations.filter(
      (x) => x.backendId == id
    )[0];
    let day = generateDay(startDate);
    let processed = [];

    for (let x = 0; x < day.length; x++) {
      let currentMinute = moment(day[x]).toDate();
      let booths_ = [];
      let stools_ = {
        Total: currentLocation.seatData?.stools.reduce(
          (y, z) => y + z.quantity,
          0
        ),
        Occupied: 0,
      };
      let chairs_ = [];

      for (let y = 0; y < data_.length; y++) {
        let seatId = Object.keys(data_[y])[0];
        let currentData = data_
          .filter((z) => seatId in z)[0]
          [seatId].filter((a) => a.time == formatTimestamp(currentMinute))?.[0];
        switch (true) {
          case seatId.startsWith("B"):
            booths_.push({
              [seatId]: currentData?.occupancy || 0,
            });
            break;
          case seatId.startsWith("S"):
            stools_.Occupied += currentData ? currentData.occupancy : 0;
            break;
          case seatId.startsWith("DC"):
            chairs_.push({
              [seatId]: currentData?.occupancy || 0,
            });
            break;
          default:
            break;
        }
      }

      let boothSum = 0;
      for (let t = 0; t < booths_.length; t++) {
        let id_ = t + 1;
        boothSum = boothSum + (booths_[t][`B${id_}`] > 0 ? 1 : 0);
      }

      let chairSum = 0;
      for (let t = 0; t < chairs_.length; t++) {
        let id_ = t + 1;
        chairSum = chairSum + chairs_[t][`DC${id_}`];
      }

      if (stools_.Occupied > currentLocation.capacity.stools) {
        stools_.Occupied = currentLocation.capacity.stools;
      }

      if (chairSum > currentLocation.capacity.chairs) {
        chairSum = currentLocation.capacity.chairs;
      }
      let perc =
        (
          (boothSum + stools_.Occupied + chairSum) /
          (currentLocation.capacity.booths +
            currentLocation.capacity.stools +
            currentLocation.capacity.chairs)
        ).toFixed(2) * 100;

      processed.push({
        TimeStamp: formatTimestamp(currentMinute),
        BoothsOccupied: boothSum,
        StoolsOccupied: stools_.Occupied,
        ChairsOccupied: chairSum,
        TotalOccupied: boothSum + stools_.Occupied + chairSum,
        OccupiedPerc: Math.round(perc),
        Booths: booths_,
        Stools: stools_,
        Chairs: chairs_,
      });
    }
    processed.sort(
      (j, k) =>
        moment(j.TimeStamp).toDate().getTime() -
        moment(k.TimeStamp).toDate().getTime()
    );
    return processed;
  };

  const getBooths = (processedData, id) => {
    let booths_ = [];
    let currentLocation = require("../floors/locations.json").locations.filter(
      (x) => x.backendId == id
    )[0];
    for (let x = 0; x < currentLocation.seatData.booths.length; x++) {
      let currentBooth = { ...currentLocation.seatData.booths[x], points: [] };
      for (let y = 0; y < processedData.length; y++) {
        currentBooth.points.push({
          time: processedData[y].TimeStamp,
          occupancy: processedData[y].Booths.filter(
            (z) => currentBooth.id in z
          )[0]?.[currentBooth.id],
        });
      }
      booths_.push({
        ...currentBooth,
        periods: getStreaks(currentBooth.points),
      });
    }
    return booths_;
  };

  function aggregate(points) {
    let chunks = [];
    for (let index = 0; index < points.length; index += 5) {
      let chunk = points.slice(index, index + 5);
      chunks.push({
        TimeStamp: moment(chunk[0].TimeStamp).toDate().getTime() / 1000,
        OccupiedPerc:
          chunk.reduce((y, z) => y + z.OccupiedPerc, 0) / chunk.length,
      });
    }
    return chunks;
  }

  const getDelta = () => {
    let deltas = [];
    for (let x = 0; x < data?.occData?.length; x++) {
      for (let y = x + 1; y < data?.occData?.length; y++) {
        let matchup = {
          Src: data?.occData[x].id,
          Dest: data?.occData[y].id,
          Deltas: [],
        };
        for (let z = 0; z < data?.occData[x].data?.length; z++) {
          let base = data?.occData[x].data[z];
          matchup.Deltas.push({
            TimeStamp: moment(base.TimeStamp).toDate().getTime() / 1000,
            Delta:
              base["OccupiedPerc"] - data?.occData[y].data[z]["OccupiedPerc"],
          });
        }
        deltas.push(matchup);
      }
    }
    return deltas;
  };

  const getReport = () => {
    setSaving(true);
    setTimeout(() => {
      const input = pdfRef.current;

      html2canvas(input).then((canvas) => {
        var imgData = canvas.toDataURL("image/png");
        var imgWidth = 210;
        var pageHeight = 297;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        var heightLeft = imgHeight;
        var doc = new jsPDF("p", "mm", [210, 297]);
        var position = 0;

        doc.addImage(
          imgData,
          "PNG",
          0,
          position,
          imgWidth,
          imgHeight,
          undefined,
          "FAST"
        );
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        doc.save(
          `Waffle House Traffic Report (${moment(startDate).format(
            "YYYY-MM-DD"
          )}).pdf`
        );
      });
    }, 2000);
    setTimeout(() => setSaving(false), 2000);
  };

  useEffect(() => {
    if (loading) {
      timer = setInterval(() => {
        setStatus((status) => status + (status < 100 ? 10 : 0));
      }, 1500);
    } else {
      clearInterval(timer);
      setStatus(15);
    }
  }, [loading]);

  useEffect(() => {
    if (
      authCtx.loggedIn &&
      moment.unix(authCtx.tokenExpirationTime) < moment(new Date())
    ) {
      authCtx.logout();
      navigate("/auth");
    }
  }, []);

  return (
    <>
      {authCtx.loggedIn &&
      moment.unix(authCtx.tokenExpirationTime) > moment(new Date()) ? (
        <div className="p-4">
          <div className="between">
            <InputGroup className="px-5">
              <div className="input-group-text">Date</div>
              <input
                value={moment(startDate).format("YYYY-MM-DD")}
                onChange={onChangeStartDate}
                type="date"
                className="form-control"
              />
              <Button
                onClick={() => getData()}
                icon="caret-right"
                text="Generate Report"
              />

              {saving && (
                <span className="btn border-0">
                  <Spinner />
                </span>
              )}

              <Button
                text="Download To PDF"
                icon="clipboard2-data"
                onClick={() => getReport()}
              />
            </InputGroup>
          </div>
          {/* <div className="px-5 mt-3">
            {[1058, 1726, 295].map((x) => (
              <Button className="active" text={x} />
            ))}
          </div> */}
          <>
            <div
              ref={pdfRef}
              className=""
              style={{ width: saving ? "1530px" : "" }}>
              {loading ? (
                <div
                  style={{ width: "100%", height: "500px" }}
                  className="text-center p-5 m-5">
                  <div className="progress" style={{ height: "25px" }}>
                    <div
                      className="progress-bar progress-bar-animated progress-bar-striped"
                      style={{ width: status.toString() + "%" }}>
                      {status.toString() + "%"}
                    </div>
                  </div>
                  <div className="p-3">
                    <Spinner className="me-2" />
                    Generating and formatting report
                  </div>
                </div>
              ) : (
                <>
                  {data.length !== 0 && (
                    <div className="mt-4">
                      <PDFPage height={saving ? 2165 : null} banner={false}>
                        <Brand reverseColors={false} size={3.5} />
                        <p className="text-center h3">
                          {moment(startDate).format("LL")}
                        </p>
                        <div className="row mt-5">
                          {booths.map((x) => (
                            <TotalBoothCard className="mb-5" location={x} />
                          ))}
                        </div>
                      </PDFPage>
                      {booths.map((x) => (
                        <PDFPage
                          heading1="Booth Analytics"
                          heading2={`Location #${x.locationId}`}
                          heading3={moment(startDate).format("LL")}
                          height={saving ? 2163 : null}>
                          <div className="row">
                            {x.booths.map((y) => (
                              <BoothCard
                                page="export"
                                key={uuidv4()}
                                id={y.id}
                                history={y.points}
                                periods={y.periods}
                              />
                            ))}
                          </div>
                        </PDFPage>
                      ))}
                      <PDFPage
                        heading1="Total Occupancy %"
                        height={saving ? 2165 : null}>
                        {data?.occData?.map((x) => (
                          <div className="row">
                            <div className="col-2 d-flex">
                              <div className="m-auto h4 text-center p-4">
                                <p className="">#{x.id}</p>
                                {/* <hr /> */}
                                <div>
                                  <div>Average Occupancy:</div>
                                  <span className="fst-italic">
                                    {Math.round(
                                      aggregate(x.data).reduce(
                                        (y, z) => y + z.OccupiedPerc,
                                        0
                                      ) / 288
                                    )}
                                    %
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-10">
                              <Chart
                                width={"100%"}
                                height={400}
                                data={aggregate(x.data)}
                                className="my-4">
                                <CartesianGrid />
                                <Tooltip
                                  content={(props) => {
                                    if (props.active) {
                                      return (
                                        <div className="bg-light p-3">
                                          <div>
                                            {
                                              props.payload[0].payload
                                                .OccupiedPerc
                                            }
                                            % Occupied
                                          </div>
                                          <div>
                                            {props.payload[0].payload.TimeStamp}
                                          </div>
                                        </div>
                                      );
                                    }
                                  }}
                                />
                                <XAxis
                                  // tick={false}
                                  tickFormatter={(x) =>
                                    new Date(x * 1000).toLocaleTimeString(
                                      "en-US",
                                      {
                                        hour: "numeric",
                                      }
                                    )
                                  }
                                  interval={11}
                                  style={{ fontSize: "small" }}
                                  domain={["auto", "auto"]}
                                  scale={"time"}
                                  type="number"
                                  dataKey={"TimeStamp"}>
                                  <Label
                                    position="bottom"
                                    offset="-6"
                                    value={"Time (EST)"}
                                  />
                                </XAxis>
                                <YAxis
                                  width={100}
                                  domain={[0, 100]}
                                  dataKey={"OccupiedPerc"}
                                  padding={{ bottom: 2 }}>
                                  <Label
                                    angle={-90}
                                    value="Total Occupancy %"
                                  />
                                </YAxis>
                                <Bar dataKey="OccupiedPerc" fill="#53b8aa">
                                  {aggregate(x.data).map((y, idx) => (
                                    <Cell
                                      key={uuidv4()}
                                      fill={
                                        y.OccupiedPerc < 66 ? "#53b8aa" : "red"
                                      }
                                    />
                                  ))}
                                </Bar>
                              </Chart>
                            </div>
                          </div>
                        ))}
                      </PDFPage>
                      <PDFPage
                        heading1="Total Occupancy (Delta)"
                        height={saving ? 2140 : null}>
                        {getDelta().map((x) => (
                          <>
                            <div className="row">
                              <div className="col-2 d-flex">
                                <div className="h4 text-center m-auto">
                                  #{x.Src} vs. #{x.Dest}
                                </div>
                              </div>
                              <div className="col-10">
                                <Chart
                                  className="mb-4"
                                  width={"99%"}
                                  height={400}
                                  data={x.Deltas}>
                                  <CartesianGrid />
                                  <XAxis
                                    style={{ fontSize: "small" }}
                                    interval={60}
                                    scale="time"
                                    type="number"
                                    domain={["auto", "auto"]}
                                    // tick={false}
                                    height={50}
                                    tickFormatter={(x) =>
                                      new Date(x * 1000).toLocaleTimeString(
                                        "en-US",
                                        {
                                          hour: "numeric",
                                        }
                                      )
                                    }
                                    dataKey="TimeStamp">
                                    <Label
                                      position="bottom"
                                      offset={-17}
                                      value="Time (EST)"
                                    />
                                  </XAxis>
                                  <YAxis dataKey="Delta" domain={[-100, 100]}>
                                    <Label
                                      position="insideLeft"
                                      angle={-90}
                                      value="Occupancy (%)"
                                    />
                                  </YAxis>
                                  <Bar dataKey="Delta">
                                    {x.Deltas.map((x) => (
                                      <Cell
                                        key={uuidv4()}
                                        fill={
                                          x["Delta"] >= 30 ? "red" : "#53b8aa"
                                        }
                                      />
                                    ))}
                                  </Bar>
                                </Chart>
                              </div>
                            </div>
                          </>
                        ))}
                      </PDFPage>
                    </div>
                  )}
                </>
              )}
            </div>
          </>
        </div>
      ) : (
        <div className="p-4">
          {authCtx.loggedIn &&
          moment.unix(authCtx.tokenExpirationTime) < moment(new Date()) ? (
            <>
              <Link to={"/auth"}>
                Session Token expired or invalid. Please log out, then log back
                in for a new session token
              </Link>
            </>
          ) : (
            <>
              <Link to={"/auth"}>
                Not Authorized. Please Log-In with given credentials or contact
                support.
              </Link>
            </>
          )}
        </div>
      )}
    </>
  );
}
