import { useContext, useState } from "react";
import { HoveredItemContext } from "../../../pages/Parking";

export default function Spot({ item, index, horizontal, originX, originY }) {
  const [hovered, setHovered] = useState(false);

  return (
    <g
      className="cursor"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{ outline: hovered ? "1px solid" : "" }}>
      <rect
        x={horizontal ? index * 40 + originX : originX}
        rx={3}
        y={horizontal ? originY : index * 40 + originY}
        ry={3}
        width={horizontal ? 35 : 60}
        height={horizontal ? 60 : 35}
        fill={
          item.boundary
            ? "white"
            : item.handicap
            ? "#3a67fc"
            : item.occupied
            ? "#c84444"
            : "#569656"
        }
      />
      {/* {item.boundary && (
        <text
          fill="white"
          fontSize={30}
          x={horizontal ? index * 40 + originX + 10 : originX + 23}
          y={horizontal ? originY + 37 : index * 40 + originY + 29}>
          x
        </text>
      )}
      {item.handicap && (
        <text
          fill="white"
          fontSize={30}
          x={horizontal ? index * 40 + originX + 7 : originX + 23}
          y={horizontal ? originY + 40 : index * 40 + originY + 29}>
          H
        </text>
      )} */}
    </g>
  );
}
