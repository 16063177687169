import { useContext } from "react";
import { HoveredItemContext } from "../../../pages/Parking";
import Spot from "./Spot";

export default function SpotGroup({ group }) {
  const [hoveredItem, setHoveredItem] = useContext(HoveredItemContext);

  return (
    <g
      x={group.x}
      y={group.y}
      // style={{ outline: "1px solid" }}
    >
      {group.spots.map((x, idx) => (
        <Spot
          originX={group.x}
          originY={group.y}
          key={x.spotId}
          item={x}
          index={idx}
          horizontal={group.horizontal}
        />
      ))}
      {/* <text x={group.x} y={group.y}>{group.groupId}</text> */}
    </g>
  );
}
