import { useContext } from "react";
import { HoveredContext } from "../../../pages/Location";

export default function Booth(props) {
  const [hoveredItem, setHoveredItem] = useContext(HoveredContext);

  const isCurrent = () => {
    if (hoveredItem.x === props.x && hoveredItem.y === props.y) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <g
        style={{
          cursor: "pointer",
          outline: isCurrent() ? "1px solid" : "",
        }}
        onMouseEnter={() => setHoveredItem({ ...props })}
        transform={
          `scale(1.3) translate(${props.x},${props.y})` +
          (props.horizontal ? " rotate(90)" : "")
        }>
        <g>
          <path
            className={(props.occupied ? "red" : "green") + "-1"}
            d="m0,10.51h12.25c2.84,0,5.14,2.3,5.14,5.14v81.74c0,2.84-2.3,5.14-5.14,5.14H0V10.51h0Z"
          />
          <path
            className={(props.occupied ? "red" : "green") + "-1"}
            d="m68.11,10.51h12.25c2.84,0,5.14,2.3,5.14,5.14v81.74c0,2.84-2.3,5.14-5.14,5.14h-12.25V10.51h0Z"
            transform="translate(153.61 113.03) rotate(-180)"
          />
          <rect
            className={(props.occupied ? "red" : "green") + "-3"}
            x="15.58"
            width="54.34"
            height="113.03"
            rx="3.5"
            ry="3.5"
          />
          <rect
            className={(props.occupied ? "red" : "green") + "-3"}
            x="83.69"
            y="8.88"
            width="1.81"
            height="95.28"
            rx=".6"
            ry=".6"
          />
          <rect
            className={(props.occupied ? "red" : "green") + "-3"}
            y="8.88"
            width="1.81"
            height="95.28"
            rx=".6"
            ry=".6"
          />
          <path
            className={(props.occupied ? "red" : "green") + "-2"}
            d="m81.96,9.42h1.73v94.19h-1.73c-2.21,0-4-1.79-4-4V13.41c0-2.21,1.79-4,4-4Z"
          />
          <path
            className={(props.occupied ? "red" : "green") + "-2"}
            d="m5.81,9.42h1.73v94.19h-1.73c-2.21,0-4-1.79-4-4V13.41c0-2.21,1.79-4,4-4Z"
            transform="translate(9.35 113.03) rotate(-180)"
          />
        </g>
        <circle
          className={(props.occupied ? "red" : "green") + "-4"}
          cx="42.49"
          cy="54.96"
          r="18"
        />
        <text
          x={props.horizontal ? -64 : 33}
          y={props.horizontal ? 48 : 60}
          transform={props.horizontal ? "rotate(-90)" : ""}>
          #{props.label}
        </text>
      </g>
    </>
  );
}
