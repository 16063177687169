import { useState } from "react";
import HistoryItem from "../FloorPlan/HistoryItem";
import { v4 as uuidv4 } from "uuid";
import Button from "../../atoms/Button";
import Icon from "../../atoms/Icon";

export default function BoothCard({ id, history, periods, page }) {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <div className="col-3 mb-3">
      <div className="border rounded p-3">
        {history.length !== 0 ? (
          <>
            <div className="h4 text-center py-2">
              Booth #{id.substring(1, id.length)}
            </div>
            <hr />
            <div className="p-1">
              <div className="text-center mb-1">
                <div>
                  <div>Total Hours Occupied:</div>
                  <div className="h3 fst-italic">
                    {(
                      periods.reduce((x, y) => x + y.length - 1, 0) / 60
                    ).toFixed(1)}{" "}
                    hours
                  </div>
                </div>
                <div className="py-2">
                  <div>Average Turn Around:</div>
                  <div className="h3 fst-italic">
                    {(isNaN(
                      periods.reduce((x, y) => x + y.length - 1, 0) /
                        periods.length
                    )
                      ? 0
                      : periods.reduce((x, y) => x + y.length - 1, 0) /
                        periods.length
                    ).toFixed(0)}{" "}
                    minutes
                  </div>
                </div>
                <div>
                  <div>Max Occupancy Duration:</div>
                  <div className="h3 fst-italic">
                    {!isFinite(Math.max(...periods.map((x) => x.length - 1)))
                      ? 0
                      : Math.max(...periods.map((x) => x.length - 1))}{" "}
                    minutes
                  </div>
                </div>

                {periods.length !== 0 && page !== "export" && (
                  <Button
                    className="my-3"
                    icon="clock-history"
                    text={(showDetails ? "Hide" : "Show") + " Full History"}
                    onClick={() => setShowDetails(!showDetails)}
                  />
                )}
              </div>
              {periods.length !== 0 && (
                <>
                  {(showDetails || page === "export") && (
                    <>
                      <hr className="my-4" />
                      <p className="h5 text-center">
                        <Icon name="watch" className="me-2" />
                        Turnaround Times
                      </p>
                      <div className="px-5 mt-4 text-center">
                        <div className="row small">
                          <span className="col">Start</span>
                          <span className="col">End</span>
                          <span className="col fw-bold">Mins.</span>
                        </div>
                        <hr className="my-1" />
                        {periods.map((y) => (
                          <HistoryItem key={uuidv4()} item={y} />
                        ))}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </>
        ) : (
          <div>No Data Available.</div>
        )}
      </div>
    </div>
  );
}
