import { v4 as uuidv4 } from "uuid";

export default function StoolGroup(props) {
  return (
    <>
      <foreignObject x={props.x} y={props.y + 40} width={450} height={20}>
        <div className="progress" style={{ height: "20px" }}>
          <div
            className="progress-bar"
            style={{
              width: (props.occupied / props.quantity) * 100 + "%",
              backgroundColor:
                props.occupied / props.quantity < 0.7
                  ? "#53b8aa"
                  : "darkorange",
            }}></div>
        </div>
      </foreignObject>
      <g
        style={{
          cursor: "pointer",
        }}>
        {Array(props.quantity)
          .fill(0)
          .map((x, idx) => (
            <circle
              key={uuidv4()}
              fill="gray"
              cx={props.x + 90 * idx}
              cy={props.y}
              r={25}
            />
          ))}
        <text
          fill="#1d2121"
          fontSize="1.1rem"
          x={props.x + 150}
          y={props.y + 85}>
          {props.occupied}/{props.quantity} stools occupied
        </text>
      </g>
    </>
  );
}
