import { useContext, useEffect, useState } from "react";
import { Dot, Label, Line, Tooltip, XAxis, YAxis } from "recharts";
import { DataContext } from "../../../pages/Location";
import moment from "moment";
import Chart from "../../atoms/Chart";

export default function TrafficChart() {
  const [processedData, , isToday, position, setPosition] =
    useContext(DataContext);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    let chunks = [];
    for (let x = 0; x < processedData.occData?.length; x += 5) {
      let chunk = processedData.occData.slice(x, x + 5);
      let avg = chunk.reduce((g, h) => g + h.OccupiedPerc, 0) / chunk.length;
      chunks.push({
        TimeStamp: moment(chunk[0].TimeStamp).toDate().getTime() / 1000,
        OccupiedPerc: chunk[0].OccupiedPerc,
      });
    }
    let cutoff =
      Math.round(
        (moment(new Date()) - moment(new Date()).startOf("day")) / 60000
      ) - 1;
    setChartData({
      occData: isToday ? chunks.slice(0, cutoff / 5 + 1) : chunks,
    });
  }, [processedData]);

  return (
    <>
      <Chart width={"100%"} height={500} data={chartData.occData}>
        <Tooltip
          content={(props) => {
            if (props.active) {
              return (
                <div className="bg-light p-3">
                  <div>{props.payload[0].payload.OccupiedPerc}% Occupied</div>
                  <div>
                    {new Date(
                      props.payload[0].payload.TimeStamp * 1000
                    ).toLocaleString()}
                  </div>
                </div>
              );
            }
          }}
        />
        <XAxis
          scale="time"
          type="number"
          height={50}
          interval={11}
          domain={["auto", "auto"]}
          tickFormatter={(x) =>
            new Date(x * 1000).toLocaleTimeString("en-US", {
              hour: "numeric",
            })
          }
          dataKey="TimeStamp">
          <Label
            position="bottom"
            offset="-10"
            value={"Time (Local Time Zone)"}
          />
        </XAxis>
        <YAxis
          padding={{ bottom: 2 }}
          domain={[0, 100]}
          dataKey="OccupiedPerc"
          width={90}>
          <Label angle={-90} value="Total Occupancy %" />
        </YAxis>
        <Line
          strokeWidth={2}
          stroke="#53b8aa"
          isAnimationActive={false}
          type={"natural"}
          dot={{ fill: "#53b8aa", strokeWidth: 0, radius: 0 }}
          activeDot={
            <Dot
              r={10}
              onClick={(e, payload) => {
                let x = new Date(payload.payload.TimeStamp * 1000);
                setPosition(Math.round((x - moment(x).startOf("day")) / 60000));
              }}
            />
          }
          dataKey="OccupiedPerc"
        />
      </Chart>
    </>
  );
}
