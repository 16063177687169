import logo from "../../assets/Waffle_House_monogram.png";
import { Link } from "react-router-dom";

export default function LocationCard({ item, percent, className = "" }) {
  return (
    <Link to={`../location/${item.id}`} className="col fw-bold">
      <div className="location-card shadow p-4">
        <div className="d-flex mb-2">
          <img
            alt=""
            src={logo}
            className="rounded-circle me-3"
            width={40}
            height={40}
          />

          <div className="h3">#{item.id}</div>
        </div>
        <div className="">{item.address}</div>
        <hr />
        <div className="">
          <div>Current Occupancy:</div>
          <div
            style={{ fontSize: "4rem" }}
            className={
              "fs-italic text-center" + (percent > 65 ? " text-warning" : "")
            }>
            {percent}%
          </div>
        </div>
      </div>
    </Link>
  );
}
