import Brand from "./Brand";
import Heading from "./Heading";

export default function PDFPage({
  className,
  height,
  children,
  banner = true,
  heading1,
  heading2,
  heading3,
}) {
  return (
    <div
      className={className}
      style={{ height: height ? `${height}px` : null }}>
      {banner && (
        <>
          <div className="between">
            <Heading
              heading1={heading1}
              heading2={heading2}
              heading3={heading3}
            />
            <Brand />
          </div>
          {/* <hr /> */}
        </>
      )}
      <div className="px-5 mt-5">{children}</div>
    </div>
  );
}
