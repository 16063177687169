import {
  Fragment,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { useLocations } from "../hooks";
import {
  formatTimestamp,
  generateDay,
  getQueuesAPI,
  getStreaks,
} from "../util";
import moment from "moment-timezone";
import Booth from "../components/organisms/FloorPlan/Booth";
import StoolGroup from "../components/organisms/FloorPlan/StoolGroup";
import ChairGroup from "../components/organisms/FloorPlan/ChairGroup";
import { v4 as uuidv4 } from "uuid";
import Popup from "../components/organisms/FloorPlan/Popup";
import BoothCard from "../components/organisms/Analytics/BoothCard";
import MinuteSummary from "../components/organisms/Analytics/MinuteSummary";
import TrafficChart from "../components/organisms/Analytics/TrafficChart";
import AuthContext from "../contexts/auth-context";
import Button from "../components/atoms/Button";
import Dropdown from "../components/atoms/Dropdown";
import Icon from "../components/atoms/Icon";
import ButtonGroup from "../components/molecules/ButtonGroup";
import InputGroup from "../components/molecules/InputGroup";
import Input from "../components/atoms/Input";
import Spinner from "../components/atoms/Spinner";
import Map from "../components/atoms/Map";

export const HoveredContext = createContext();
export const ExpandedContext = createContext();
export const DataContext = createContext();

moment.tz.setDefault("America/New_York");

export default function Location() {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const { id } = useParams();
  const { locations } = useLocations();
  const [currentLocation, setCurrentLocation] = useState([]);
  const [data, setData] = useState([]);
  const [processedData, setProcessedData] = useState([]);
  const [currentTime, setCurrentTime] = useState(
    moment(new Date()).startOf("day").toDate()
  );
  const [loading, setLoading] = useState(true);
  const [hoveredItem, setHoveredItem] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [position, setPosition] = useState(0);
  const [booths, setBooths] = useState([]);

  const [showDetails, setShowDetails] = useState(false);

  const [isToday, setIsToday] = useState(true);

  const onChangePosition = (e) => setPosition(e.target.value);

  const onChangeCurrentTime = (e) =>
    setCurrentTime(moment(e.target.value).toDate());

  const getData = () => {
    setLoading(true);
    getQueuesAPI(
      "get-waffle-house-data",
      {
        locationId: currentLocation.backendId,
        date: moment(currentTime).format("yyyy-MM-DD"),
      },
      (data) => setData({ occData: data }),
      authCtx.token
    );
  };

  const processData = () => {
    let day = generateDay(currentTime);
    let processed = [];

    for (let x = 0; x < day.length; x++) {
      let currentMinute = moment(day[x]).toDate();
      let booths_ = [];
      let stools_ = {
        Total: currentLocation.seatData?.stools.reduce(
          (y, z) => y + z.quantity,
          0
        ),
        Occupied: 0,
      };
      let chairs_ = [];

      for (let y = 0; y < data.occData.length; y++) {
        let seatId = Object.keys(data.occData[y])[0];
        let currentData = data?.occData
          .filter((z) => seatId in z)[0]
          [seatId].filter((a) => a.time == formatTimestamp(currentMinute))?.[0];
        switch (true) {
          case seatId.startsWith("B"):
            booths_.push({
              [seatId]: currentData?.occupancy || 0,
            });
            break;
          case seatId.startsWith("S"):
            stools_.Occupied += currentData ? currentData.occupancy : 0;
            break;
          case seatId.startsWith("DC"):
            chairs_.push({
              [seatId]: currentData?.occupancy || 0,
            });
            break;
          default:
            break;
        }
      }

      let boothSum = 0;
      for (let t = 0; t < booths_.length; t++) {
        let id_ = t + 1;
        boothSum = boothSum + (booths_[t][`B${id_}`] > 0 ? 1 : 0);
      }

      let chairSum = 0;
      for (let t = 0; t < chairs_.length; t++) {
        let id_ = t + 1;
        chairSum = chairSum + chairs_[t][`DC${id_}`];
      }

      if (stools_.Occupied > currentLocation.capacity.stools) {
        stools_.Occupied = currentLocation.capacity.stools;
      }

      if (chairSum > currentLocation.capacity.chairs) {
        chairSum = currentLocation.capacity.chairs;
      }
      let perc =
        (
          (boothSum + stools_.Occupied + chairSum) /
          (currentLocation.capacity.booths +
            currentLocation.capacity.stools +
            currentLocation.capacity.chairs)
        ).toFixed(2) * 100;

      processed.push({
        TimeStamp: formatTimestamp(currentMinute),
        BoothsOccupied: boothSum,
        StoolsOccupied: stools_.Occupied,
        ChairsOccupied: chairSum,
        TotalOccupied: boothSum + stools_.Occupied + chairSum,
        OccupiedPerc: Math.round(perc),
        Booths: booths_,
        Stools: stools_,
        Chairs: chairs_,
      });
    }
    processed.sort(
      (j, k) =>
        moment(j.TimeStamp).toDate().getTime() -
        moment(k.TimeStamp).toDate().getTime()
    );
    setProcessedData({ occData: processed });
  };

  const getBooths = () => {
    let booths_ = [];
    for (let x = 0; x < currentLocation.seatData.booths.length; x++) {
      let currentBooth = { ...currentLocation.seatData.booths[x], points: [] };
      for (let y = 0; y < processedData.occData.length; y++) {
        currentBooth.points.push({
          time: processedData.occData[y].TimeStamp,
          occupancy: processedData.occData[y].Booths.filter(
            (z) => currentBooth.id in z
          )[0]?.[currentBooth.id],
        });
      }
      booths_.push({
        ...currentBooth,
        periods: getStreaks(currentBooth.points),
      });
    }
    setBooths(booths_);
    setLoading(false);
  };

  useEffect(() => {
    setCurrentLocation(
      require("../floors/locations.json").locations.filter((x) => x.id == id)[0]
    );
  }, [id]);

  useEffect(() => {
    if (currentLocation.length !== 0 && authCtx.loggedIn) {
      getData();
      setHoveredItem([]);
    }
  }, [currentLocation]);

  useEffect(() => {
    if (data.length !== 0) {
      processData();
    }
  }, [data]);

  useEffect(() => {
    if (processedData.length !== 0) {
      getBooths();
    }
  }, [processedData]);

  const refresh = () => {
    getData();
    setPosition(
      Math.round(
        (moment(new Date()) - moment(new Date()).startOf("day")) / 60000
      ) - 1
    );
  };

  useEffect(() => {
    if (
      authCtx.loggedIn &&
      moment.unix(authCtx.tokenExpirationTime) < moment(new Date())
    ) {
      authCtx.logout();
      navigate("/auth");
    }
    setPosition(
      Math.round(
        (moment(new Date()) - moment(new Date()).startOf("day")) / 60000
      ) - 1
    );
  }, []);

  useEffect(() => {
    setCurrentTime(
      moment(currentTime).startOf("day").add(position, "minutes").toDate()
    );
  }, [position]);

  useEffect(() => {
    setIsToday(
      moment(currentTime).format("L") === moment(new Date()).format("L")
    );
  }, [currentTime]);

  useEffect(() => {
    setTimeout(
      () =>
        setPosition(
          isToday
            ? Math.round(
                (moment(new Date()) - moment(new Date()).startOf("day")) / 60000
              ) - 1
            : 0
        ),
      500
    );
  }, [isToday]);

  return (
    <>
      {authCtx.loggedIn &&
      moment.unix(authCtx.tokenExpirationTime) > moment(new Date()) ? (
        <div className="px-5 py-4">
          <div className="d-flex justify-content-between py-2 sticky-top">
            <InputGroup className="w-50">
              <ButtonGroup>
                {loading ? (
                  <span className="input-group-text">
                    <Spinner color="#1a1a1a" />
                  </span>
                ) : (
                  <Button
                    icon="arrow-clockwise"
                    className="border-0"
                    onClick={() => refresh()}
                  />
                )}
                <Dropdown
                  className="btn-group"
                  classNameBtn="btn"
                  target="locations"
                  icon="hash"
                  text={id}>
                  {locations.map((x) => (
                    <Fragment key={x.id}>
                      {x.id != id && (
                        <Link
                          className="dropdown-item"
                          to={`../location/${x.id}`}>
                          {x.id}
                        </Link>
                      )}
                    </Fragment>
                  ))}
                </Dropdown>
                <Button
                  className="bg-danger text-light border-0"
                  icon="clock"
                  text={currentTime.toLocaleTimeString("en-US", {
                    hour: "2-digit",
                    minute: "numeric",
                  })}
                />
              </ButtonGroup>
              <Input
                onChange={onChangeCurrentTime}
                type="date"
                value={moment(currentTime).format("yyyy-MM-DD")}
                max={moment(new Date()).format("yyyy-MM-DD")}
              />
              <Button
                onClick={() => getData()}
                icon="caret-right"
                text="Go To Date"
              />
            </InputGroup>
            <span className="input-group-text">
              <Icon name="geo-fill" className="me-2" />
              {currentLocation.address}
            </span>
          </div>
          <hr />

          {loading ? (
            <div
              style={{ width: "100%", height: "500px" }}
              className="text-center p-5 m-5">
              <Spinner size={100} />
            </div>
          ) : (
            <div>
              <div>
                <div className="row">
                  <div
                    className={
                      "overflow-scroll col" + (showDetails ? "-8" : "-12")
                    }>
                    <Button
                      icon="list-task"
                      size="sm"
                      onClick={() => setShowDetails(!showDetails)}
                      text={(showDetails ? "Hide" : "Show") + " Summary"}
                    />
                    <Map
                      imageBg={require(`../floors/${id}.png`)}
                      outerWidth={"100%"}
                      outerHeight={650}
                      viewBoxWidth={1920}
                      viewBoxHeight={1080}>
                      <HoveredContext.Provider
                        value={[hoveredItem, setHoveredItem]}>
                        <ExpandedContext.Provider
                          value={[expanded, setExpanded]}>
                          {currentLocation.seatData?.booths.map((x, idx) => (
                            <Booth
                              key={uuidv4()}
                              points={
                                booths.filter((y) => y.id == x.id)[0]?.points
                              }
                              periods={
                                booths.filter((y) => y.id == x.id)[0]?.periods
                              }
                              id={x.id}
                              occupied={
                                processedData.occData?.[position]?.[
                                  "Booths"
                                ].filter((b) => x.id in b)?.[0]?.[x.id] > 0
                              }
                              x={x.x}
                              y={x.y}
                              label={idx + 1}
                              longLabel={`Booth #${idx + 1}`}
                              horizontal={x.horizontal}
                            />
                          ))}
                          {currentLocation.seatData?.stools.map((x, idx) => {
                            let occ =
                              processedData.occData?.[position]?.["Stools"]
                                ?.Occupied;
                            let quant =
                              processedData.occData?.[position]?.["Stools"]
                                ?.Total;
                            return (
                              <StoolGroup
                                key={uuidv4()}
                                x={x.x}
                                y={x.y}
                                quantity={quant}
                                occupied={occ}
                              />
                            );
                          })}
                          {currentLocation.seatData?.chairs.map((x, idx) => {
                            let total =
                              currentLocation?.seatData?.chairs?.reduce(
                                (y, z) => y + z.quantity,
                                0
                              );
                            return (
                              <ChairGroup
                                key={uuidv4()}
                                horizontal={x.horizontal}
                                x={x.x}
                                y={x.y}
                                total={total}
                                quantity={x.quantity}
                                occupied={
                                  processedData.occData?.[position]?.[
                                    "Chairs"
                                  ].filter((b) => x.id in b)?.[0]?.[x.id]
                                }
                              />
                            );
                          })}
                          <>{hoveredItem.length !== 0 && <Popup />}</>
                        </ExpandedContext.Provider>
                      </HoveredContext.Provider>
                    </Map>
                  </div>
                  {showDetails && (
                    <div className="col-4">
                      <div className="d-flex w-100 h-100">
                        <MinuteSummary
                          className="my-auto w-100"
                          timestamp={currentTime}
                          label=""
                          boothsTaken={
                            processedData.occData?.[position].BoothsOccupied
                          }
                          boothsTotal={booths.length}
                          stoolsTaken={
                            processedData.occData?.[position].StoolsOccupied
                          }
                          stoolsTotal={currentLocation.capacity?.stools}
                          chairsTaken={
                            processedData.occData?.[position].ChairsOccupied
                          }
                          chairsTotal={currentLocation.capacity?.chairs}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <InputGroup className="mt-4">
                  <span>{moment(currentTime).format("LT")}</span>
                  <Input
                    onChange={onChangePosition}
                    min={0}
                    max={
                      !isToday
                        ? 24 * 60 - 1
                        : Math.round(
                            (moment(new Date()) -
                              moment(new Date()).startOf("day")) /
                              60000
                          ) - 1
                    }
                    step={1}
                    value={position}
                    type="range"
                    className="form-range border-0 p-2"
                  />
                </InputGroup>
                <hr className="my-5" />
              </div>

              <DataContext.Provider
                value={[
                  processedData,
                  setProcessedData,
                  isToday,
                  position,
                  setPosition,
                ]}>
                <div className="my-3">
                  <TrafficChart />
                </div>
              </DataContext.Provider>
              <hr />
              <div className="row">
                {booths.map((x) => (
                  <BoothCard
                    key={uuidv4()}
                    id={x.id}
                    history={x.points}
                    periods={x.periods}
                  />
                ))}
              </div>
              <hr />

              {/* <MinuteSummary
                timestamp={currentTime}
                label="Peak Occupancy"
                boothsTaken={processedData.occData?.[position].BoothsOccupied}
                boothsTotal={booths.length}
                stoolsTaken={processedData.occData?.[position].StoolsOccupied}
                stoolsTotal={currentLocation.capacity?.stools}
                chairsTaken={processedData.occData?.[position].ChairsOccupied}
                chairsTotal={currentLocation.capacity?.chairs}
              /> */}
            </div>
          )}
        </div>
      ) : (
        <div className="p-4">
          {authCtx.loggedIn &&
          moment.unix(authCtx.tokenExpirationTime) < moment(new Date()) ? (
            <>
              <Link to={"/auth"}>
                Session Token expired or invalid. Please log out, then log back
                in for a new session token
              </Link>
            </>
          ) : (
            <>
              <Link to={"/auth"}>
                Not Authorized. Please Log-In with given credentials or contact
                support.
              </Link>
            </>
          )}
        </div>
      )}
    </>
  );
}
