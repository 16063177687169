export default function ProgressBar({ occupied, total, className, label }) {
  const percent = Math.round((occupied / total) * 100);

  return (
    <div className={className}>
      <div className="progress">
        <div
          className={
            "progress-bar" +
            (percent > 66 && percent < 90
              ? " bg-warning"
              : percent > 90
              ? " bg-danger"
              : "")
          }
          style={{ width: percent + "%" }}></div>
      </div>
      <div className="mt-1">
        {occupied > total ? total : occupied}/{total} {label} (
        {(percent > 100 ? 100 : percent) + "%"})
      </div>
    </div>
  );
}
