import { useEffect, useState } from "react";

export default function TotalBoothCard({ className, location }) {
  const [metrics, setMetrics] = useState([]);

  useEffect(() => getMetrics(), []);

  const getMetrics = () => {
    let totalHours = 0;
    let numTurnarounds = 0;
    for (let x = 0; x < location.booths.length; x++) {
      let currentBooth = location.booths[x];
      numTurnarounds += currentBooth.periods.length;
      totalHours += currentBooth.periods.reduce((y, z) => y + z.length - 1, 0);
    }
    setMetrics({
      totalHours: totalHours / 60,
      totalHoursAvg: totalHours / 60 / location.booths.length,
      avgTurnaround:
        location.booths.reduce(
          (a, b) =>
            a +
            b.periods.reduce((x, y) => x + y.length - 1, 0) /
              (b.periods.length || 1),
          0
        ) / location.booths.length,
      numTurnarounds: numTurnarounds,
    });
  };

  return (
    <div className={className + " col-4 text-center"}>
      <div className="p-3 border rounded">
        <div className="h2">Location #{location.locationId}</div>
        <hr />
        <div>
          <div className="h5">Total Hours:</div>
          <div className="h2 fst-italic">
            {metrics?.totalHours?.toFixed(1)} hrs.
          </div>
        </div>
        <div>
          <div className="h5">Avg Hours Occupied Per Booth:</div>
          <div className="h2 fst-italic">
            {metrics?.totalHoursAvg?.toFixed(1)} hrs.
          </div>
        </div>
        <div>
          <div className="h5">Average Turnaround:</div>
          <div className="h2 fst-italic">
            {metrics?.avgTurnaround?.toFixed(1)} mins.
          </div>
        </div>
        <div>
          <div className="h5">Number of Turnarounds:</div>
          <div className="h2 fst-italic">{metrics?.numTurnarounds}</div>
        </div>
      </div>
    </div>
  );
}
