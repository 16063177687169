export default function Map({
  className,
  imageBg,
  outerWidth,
  outerHeight,
  viewBoxWidth,
  viewBoxHeight,
  children,
}) {
  return (
    <svg
      width={outerWidth || 300}
      height={outerHeight || 300}
      viewBox={`0 0 ${viewBoxWidth || outerWidth || 300} ${
        viewBoxHeight || outerHeight || 300
      }`}
      className={className}>
      {imageBg && <image href={imageBg} />}
      {children}
    </svg>
  );
}
