import {
  Fragment,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import AuthContext from "../contexts/auth-context";
import { Link, useNavigate } from "react-router-dom";
import Spot from "../components/organisms/ParkingMap/Spot";
import { useLocations } from "../hooks";
import moment from "moment";
import SpotGroup from "../components/organisms/ParkingMap/SpotGroup";
import Button from "../components/atoms/Button";
import Dropdown from "../components/atoms/Dropdown";
import Icon from "../components/atoms/Icon";
import ButtonGroup from "../components/molecules/ButtonGroup";
import Map from "../components/atoms/Map";

export const HoveredItemContext = createContext();

export default function Parking() {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const [hoveredItem, setHoveredItem] = useState([]);
  const { locations } = useLocations();
  const [currentLocation, setCurrentLocation] = useState([]);
  const [spots, setSpots] = useState([]);
  const [open, setOpen] = useState(0);
  const [slider, setSlider] = useState(0);
  const [time, setTime] = useState(moment(new Date()).startOf("day").toDate());

  const onChangeSlider = (e) => setSlider(e.target.value);

  useEffect(() => {
    if (
      authCtx.loggedIn &&
      moment.unix(authCtx.tokenExpirationTime) < moment(new Date())
    ) {
      authCtx.logout();
      navigate("/auth");
    }
    setCurrentLocation(require("../floors/locations.json").locations[0]);
  }, []);

  useEffect(() => {
    if (currentLocation.length !== 0) {
      setSpots(currentLocation.parkingSpots);
      setOpen(
        currentLocation.parkingSpots.reduce(
          (x, y) => x + y.spots.filter((z) => !z.occupied).length,
          0
        )
      );
    }
  }, [currentLocation]);

  useEffect(() => {
    setTime(moment(time).startOf("day").add(slider, "minutes"));
  }, [slider]);

  return (
    <>
      {authCtx.loggedIn &&
      moment.unix(authCtx.tokenExpirationTime) > moment(new Date()) ? (
        <div className="d-flex p-4">
          <div className="mx-auto">
            <ButtonGroup className="mb-3">
              <Dropdown
                className="btn-group"
                target="parkingLots"
                classNameBtn="btn"
                icon="hash"
                text={currentLocation.id}>
                {locations.map((x) => (
                  <Fragment key={x.id}>
                    {x.id !== currentLocation.id && (
                      <button
                        onClick={() =>
                          setCurrentLocation(
                            locations.filter((y) => y.id === x.id)[0]
                          )
                        }
                        className="dropdown-item">
                        {x.id}
                      </button>
                    )}
                  </Fragment>
                ))}
              </Dropdown>
              <Button text={moment(new Date()).format("l")} />
              <Button text={moment(time).format("LT")} />
              <span className="input-group-text">{open} Spots Available</span>
            </ButtonGroup>

            <Map outerWidth={1350} outerHeight={400} className="border rounded">
              <HoveredItemContext.Provider
                value={[hoveredItem, setHoveredItem]}>
                {spots.map((x) => (
                  <SpotGroup key={x.groupId} group={x} />
                ))}
              </HoveredItemContext.Provider>
              {hoveredItem.length !== 0 && (
                <foreignObject
                  className="border rounded shadow-sm p-3 bg-light"
                  x={hoveredItem.x + 40}
                  y={hoveredItem.y}
                  width={200}
                  height={200}>
                  <div className="text-center">
                    <div>Spot #{hoveredItem.spotId}</div>
                    <div>{hoveredItem.occupied ? "Occupied" : "Empty"}</div>
                    {hoveredItem.handicap && (
                      <div className="h1">
                        <Icon name="person-wheelchair" />
                      </div>
                    )}
                  </div>
                </foreignObject>
              )}
            </Map>
            <input
              value={slider}
              min={0}
              onChange={onChangeSlider}
              type="range"
              className="form-range my-3"
              max={1439}
              step={1}
            />
          </div>
        </div>
      ) : (
        <div className="p-4">
          {authCtx.loggedIn &&
          moment.unix(authCtx.tokenExpirationTime) < moment(new Date()) ? (
            <>
              <Link to={"/auth"}>
                Session Token expired or invalid. Please log out, then log back
                in for a new session token
              </Link>
            </>
          ) : (
            <>
              <Link to={"/auth"}>
                Not Authorized. Please Log-In with given credentials or contact
                support.
              </Link>
            </>
          )}
        </div>
      )}
    </>
  );
}
