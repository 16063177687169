import moment from "moment";
import Icon from "../../atoms/Icon";

export default function HistoryItem({ item }) {
  return (
    <div className="row border-bottom" style={{ fontSize: ".75rem" }}>
      <span className="col border-end">
        {moment(item[0].time).format("LT")}
      </span>
      <span className="col border-end">
        {moment(item[item.length - 1].time).format("LT")}{" "}
      </span>
      <span className="col fw-bold">{item.length - 1}</span>
    </div>
  );
}
