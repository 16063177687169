import moment from "moment";
import ProgressBar from "../../atoms/ProgressBar";

export default function MinuteSummary({
  className,
  timestamp,
  label,
  boothsTaken,
  boothsTotal,
  stoolsTaken,
  stoolsTotal,
  chairsTaken,
  chairsTotal,
}) {
  return (
    <div className={className}>
      {/* <div className="h3 pb-3">{moment(timestamp).format("LT")}</div> */}
      <ProgressBar
        className="my-4"
        occupied={boothsTaken}
        total={boothsTotal}
        label="Booths Occupied"
      />
      <ProgressBar
        className="my-4"
        occupied={stoolsTaken}
        total={stoolsTotal}
        label="Stools Occupied"
      />
      <ProgressBar
        className="my-4"
        occupied={chairsTaken}
        total={chairsTotal}
        label="Chairs Occupied"
      />
      <hr className="my-5" />
      <ProgressBar
        className=""
        occupied={boothsTaken + stoolsTaken + chairsTaken}
        total={boothsTotal + stoolsTotal + chairsTotal}
        label="Total Seats Occupied"
      />
    </div>
  );
}
