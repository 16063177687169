export default function Heading({ className, heading1, heading2, heading3 }) {
  return (
    <div className={className + " my-auto"}>
      <div className="ps-5">
        {heading1 && <h1>{heading1}</h1>}
        {heading2 && <h2>{heading2}</h2>}
        {heading3 && <h3>{heading3}</h3>}
      </div>
    </div>
  );
}
