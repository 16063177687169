import logo from "../../../assets/logo_1x_white.png";
import logo2 from "../../../assets/logo_1x.png";
import logo3 from "../../../assets/Waffle_House_monogram.png";

export default function Brand({ className, reverseColors = true, size = 1.5 }) {
  const styles = {
    backgroundColor: reverseColors ? "#53b8aa" : null,
    color: reverseColors ? "white" : "black",
    borderBottomLeftRadius: "25px",
    // borderBottomRightRadius: "25px",
    fontSize: `${size}rem`,
  };

  return (
    <div className={className + " py-3 px-5"} style={styles}>
      <div className="text-center">
        <img
          width={100 * (size * 0.75)}
          height={100 * (size * 0.75)}
          src={logo3}
          className="mb-3"
        />
        <div style={{ fontSize: styles.fontSize }}>
          <img
            width={100 * (size * 0.75)}
            height={28 * (size * 0.75)}
            src={reverseColors ? logo : logo2}
            className="me-3"
          />
          Analytics Summary
        </div>
      </div>
    </div>
  );
}
